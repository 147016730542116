/**
 * Here are all the Backend api calls for our modules
 */
import appApiClient from '../app-api-client'

const pageUriPrefix = "/admin/exports/csv";

export default {

    getIndexData() {
        return appApiClient().get(pageUriPrefix+'/index');
    },

    getSalesRecordsDownloadUrl(data) {
        return appApiClient().post(pageUriPrefix+'/get-sales-records-download-url',data);
    },

    getPaymentRecordsDownloadUrl(data) {
        return appApiClient().post(pageUriPrefix+'/get-payments-records-download-url',data);
    },
}
